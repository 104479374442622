<template>
  <div class='relative'>
    <div>
      banner
    </div>
    <div class='h-full relative'>
      <content-background-cover :background-color='colorsInRange[0]' class='z-0' />
      <social-media-style-images section-title='title' />
    </div>
  </div>
</template>

<script>
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
import SocialMediaStyleImages from '@/components/social-media-style/SocialMediaStyleImages.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ParticipantImages',
  components: {
    ContentBackgroundCover,
    SocialMediaStyleImages,
  },
  computed: {
    ...mapGetters('events', [
      'colorsInRange',
    ]),
  },

}
</script>
